import BusinessView from './businessView';
import { Container } from '../ui-elements/basic-ui';
import useTranslate from '../util/dictionary';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import organisationService from '../services/business/organisationService';

export default function BusinessMain() {

    const [counters, setCounters] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        const getCounters = async () => {
            const organisation = await organisationService.get();
            setCounters(organisation.counters);
        };

        getCounters();
    }, []);

    return (
        <div style={{ padding: "1em" }}>
            {
                counters.map(counter =>
                    <Container
                        key={counter._id}
                        style={{ 
                            width: "6em",
                            height: "6em", 
                            cursor: "pointer",
                            display: "inline-block",
                            verticalAlign: "top",
                            margin: "1em",
                            fontWeight: "normal"
                        }}
                        onClick={() => navigate(`./orders/${counter.passcode}`)}
                    >
                        <h5 className="highlight" style={{margin: 0}}>{counter.passcode.toUpperCase()}</h5>
                        <p style={{margin: "1em", fontSize: "0.7em"}}>{counter.barName}</p>
                        <p style={{margin: "1em", fontSize: "0.7em"}}>{counter.counterName || "-"}</p>
                    </Container>
            )}

        </div>
    );
}