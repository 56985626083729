import { useState, useEffect } from "react";
import ThemeButton from "../../ui-elements/theme-button";
import Modal from "../../ui-elements/modal";
import { Container, Input } from "../../ui-elements/basic-ui";
import useTranslate from "../../util/dictionary";
import tableService from '../../services/business/tableService';
import BackButton from "../../common/backbutton";
import { toastError } from '../../util/toast';

export default function TableForm(props) {
    const tr = useTranslate();
    const { counter, setCounters, counters } = props;

    const [creatingTable, setCreatingTable] = useState(false);
    const [editingTable, setEditingTable] = useState(null);

    const [description, setDescription] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        if(editingTable) {
            setDescription(editingTable.description);
            setCode(editingTable.code);
        }
    }, [editingTable]);

    return (
        <div>
            {
                counter.tables && counter.tables.length > 0 && <h3>{tr("tables")}</h3>
            }

            <div style={{
                marginBottom: 16,
            }}>
                {counter.tables && counter.tables.map(table =>
                    <Container
                        style={{
                            marginBottom: "1em",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                        }}
                        key={table._id}
                    >
                        <span style={{ marginRight: 16, display: "flex", flexDirection: "column", textAlign: "left" }}>
                            <div>
                                <span style={{
                                    opacity: 0.8,
                                }}>{tr("table")}</span>
                                {" "}
                                <span style={{
                                    fontWeight: "bold",
                                }}>{table.code}</span>
                            </div>
                            <p style={{
                                opacity: 0.8,
                                fontSize: 12,
                                maxWidth: 200,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>{table.description}</p>
                        </span>
                        <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                            <ThemeButton
                                icon="edit"
                                onClick={(e) => {
                                    setEditingTable(table);
                                }}
                            />
                            <ThemeButton
                                icon="delete"
                                onClick={() => {
                                    tableService.delete(table._id, counter.passcode)
                                        .then(() => {
                                            setCounters(counters.map(c => {
                                                if (c._id === counter._id) {
                                                    return { ...c, tables: c.tables.filter(t => t._id !== table._id) };
                                                }
                                                return c;
                                            }));
                                        }
                                        ).catch(err => {
                                            toastError(tr("error"));
                                            console.log(err);
                                        }
                                        );
                                }}
                            />
                        </div>
                    </Container>
                )}
                <div>
                    <button 
                        onClick={
                            () => {
                                setCreatingTable(true);
                                tableService.create({
                                    counterId: counter.passcode,
                                    code: counter.tables.map(t => parseInt(t.code || 0)).sort((a, b) => a - b).pop() + 1 || 1,
                                }).then(table => {
                                    setCounters(counters.map(c => {
                                        if (c._id === counter._id) {
                                            return { ...c, tables: [...c.tables, table] };
                                        }
                                        return c;
                                    }));
                                    setCreatingTable(false);
                                }
                                ).catch(err => {
                                    toast(tr("error"), "error");
                                    console.log(err);
                                })
                            }
                        }
                        disabled={creatingTable}
                    >
                        {counter.tables && counter.tables.length === 0 ? tr("activate_table_service") : tr("add_table")}
                    </button>
                </div>
            </div>
            {
                <Modal open={editingTable !== null} onClose={() => { setEditingTable(null) }}>
                    <BackButton override={() => setIsEditing(false)} />
                    <h5 style={{marginTop: "5em"}}>{
                        <span>
                            {tr("edit_table")}
                            {" "}
                            <span style={{
                                fontWeight: "bold",
                            }}>
                                {editingTable ? editingTable.code : ""}
                            </span>
                        </span>
                    }</h5>
                    <Input
                        margin="normal"
                        id="tableDescription"
                        label="table_info"
                        name="tableDescription"
                        autoFocus
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength={50}
                        helperText={tr("table_info_helper")}

                    />
                    <Input
                        required
                        id="tableCode"
                        label="table_code"
                        name="tableCode"
                        type='number'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        max="1000"
                        min="1"
                        //TODO: Validate code 1-1000 and not already in use
                        helperText={tr("table_code_helper")}
                    />

                    <button
                        type="button"
                        onClick={() => {
                            props.handleTableEdit(description, code, editingTable._id);
                            setEditingTable(null);
                        }}
                    >
                        {tr("edit")}
                    </button>
                </Modal>
            }
        </div>
    )
}