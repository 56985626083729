import { useState, useEffect } from "react";
import { useLocalStorageState } from "../hooks/localStorageHook";
import { Mobile } from "../ui-elements/basic-ui";
import { useNavigate } from "react-router-dom";
import Header from "../ui-elements/header";
import useTranslate from "../util/dictionary";
import customerService from "../services/customerService";
import { toastSuccess, toastError } from "../util/toast";
import { createBrowserNotification } from "../util/notification";

export default function NotificationSettings() {
    const tr = useTranslate();

    const [shoppingCart] = useLocalStorageState("shoppingCart", {});
    const [notificationsSet, setNotificationsSet] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const notificationSetting = localStorage.getItem("notificationSetting");

        if(notificationSetting) {
            setNotificationsSet(true);
        }
    }, []);

   
    const handlePermissionCheck = () => {
        Notification.requestPermission((result) => {
            console.log("result ", result);
            if (result === "denied") {
                toastError(tr("notifications_must_be_accepted"));
            } else {
                localStorage.setItem("notificationSetting", true);
                handleSubmitNotification();
            }
        });
    }

    const getNotificationPayload = () => {
        return createBrowserNotification();
    }

    const handleSubmitNotification = async () => {
        try {
            const payload = await getNotificationPayload()
            await customerService.subscribeToNotifications(
                shoppingCart.order,
                {...payload, tr: tr("notification_package_object")}
            )
            toastSuccess(tr("notification_created"))
            navigate("/")
        } catch (e) {
            console.log("ERROR ", e);
            toastError(tr("something_went_wrong"));
        }
    }

    const skipNotifications = async () => {
        if(notificationsSet) {
            await customerService.unsubscribeFromNotifications(shoppingCart.order);    
        }
        localStorage.setItem("notificationSetting", false);
        navigate("/");
    }


    return (
        <Mobile footer-graphics pad>
            <Header />
            {notificationsSet ?
                <h5>
                    {tr("notification_options_title")}
                </h5> :
                <>
                <h5 style={{animation: "fadedown 1s forwards"}}>
                    {tr("notification_plead_0")}
                </h5>
                <h5 className="highlight" style={{animation: "fadedown 3s forwards"}}>
                    {tr("notification_plead_1")}
                </h5>
                <h6 style={{marginTop: "1em", animation: "fadedelay 5s forwards"}}>
                    {tr("notification_plead_2")}
                </h6>
                </>
            }
            <div
                style={{
                    animation: !notificationsSet ? "fadedelay 5s forwards" : "none"
                }}
            >

                <button
                    type="radio"
                    name="notification"
                    onClick={handlePermissionCheck}
                >
                    {tr("browser_notification")}
                </button>
                <button
                    onClick={skipNotifications}
                    style={{
                        backgroundColor: "transparent",
                        border: "2px solid white",
                        color: "white"
                    }}
                >
                    {notificationsSet ? tr("stop_notifications") : tr("skip_notification")}
                </button>
                {
                    notificationsSet &&
                    <button
                        onClick={() => navigate("/")}
                        style={{
                            backgroundColor: "transparent",
                            border: "2px solid white",
                            color: "white"
                        }}
                    >
                        {tr("cancel")}
                    </button>
                }
            </div>
        </Mobile>
    );
}