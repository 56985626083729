import { toast as toasts } from "react-toastify";
import useTranslate from "./dictionary";
import { Icon } from "../ui-elements/basic-ui";

/**
 * @param {string} message
 * @param {"success" | "error" | "info" | "warning" | "default"} type
 */

export const toast = (message, type, opt) => {
    let color = "var(--highlight)";

    if(type === "success") {
        color = "#88ff3e";
    }
    
    if(type === "info") {
        color = "#3eabff";
    }
    toasts(message, {
        type: type,
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
        style: {
            fontSize: ".7em",
            padding: "1em",
            border: `3px solid ${color}`,
            borderRadius: "0px",
            backgroundColor: "var(--medium)",
            color: "white"
        },
        ...opt
    })
}

export const toastRestError = (tr, e) => {
    let txt;
    if (e.response.status === 422) {
        txt = tr("invalid_user_input");
    }

    if (e.response.status === 500) {
        txt = tr("internal_server_error");
    }

    toast(txt, null, {icon: () => <Icon icon="alert"/>});
}

export const toastError = (error) => {
    toast(error, "error", {icon: () => <Icon icon="alert"/>});
}

export const toastSuccess = (message) => {
    toast(message, "success", {icon: () => <Icon icon="accept"/>});
}

export const toastInfo = (message) => {
    toast(message, "info", {icon: () => <Icon icon="information"/>});
}