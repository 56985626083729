export default function Graphics({className}){
  return (
    <>
      <img
        src="/images/graphic_left.svg"
        style={{
          position: "fixed",
          left: "0em",
          top: "3em",
          opacity: 0.3,
          zIndex: -1
        }}
        className={className}
      />
      <img
        src="/images/graphic_right.svg"
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          opacity: 0.3,
          zIndex: -1
        }}
      />
    </>
  );
}