import axios from "axios";
import useAuth from "../auth";

const baseUrl = "/api/business/order";

const businessOrderService = {
    getOrders: async (counterPass) => {
        return (await axios.get(baseUrl,
            {
                params: {
                    counter: counterPass
                },
                ...useAuth()
            }))?.data;
    },
    getOrderHistory: async (counterPass, date) => {
        return (await axios.get(`${baseUrl}/${counterPass}/history?date=${date}`,
            {
                ...useAuth()
            }))?.data;
    },
    refundOrder: async (orderId) => {
        return (await axios.put(`${baseUrl}/${orderId}/refund`, null, useAuth()))?.data;
    },
    markReady: async (orderId) => {
        return (await axios.put(`${baseUrl}/${orderId}/ready`, null, useAuth()))?.data;
    },

    markDelivered: async (orderId) => {
        return (await axios.put(`${baseUrl}/${orderId}/delivered`, null, useAuth()))?.data;
    }

};

export default businessOrderService;