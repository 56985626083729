const VAPID_PUBLIC_KEY = "BHHgElP8gH0c-GP_x3ocap58mjAGCUEsPKXHI1SBE0HACl3j6ZjZ-Qd8QNJSmWnEOQVwWb49e0elr9zaPi9aG6Y";

const NOTIFICATION_TYPE = {
    BROWSER: "BROWSER"
}

export const createBrowserNotification = async () => {
    if ('serviceWorker' in navigator) {
        return navigator.serviceWorker.ready.then((async registration => {
            const data = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: VAPID_PUBLIC_KEY,
            });
            return {
                type: NOTIFICATION_TYPE.BROWSER,
                data,
            }
        }))
    } else {
        throw new Error("Service worker is not supported")
    }
}