import { useState, useEffect } from "react";
import { Link, Navigate, useOutlet } from "react-router-dom";
import { checkAuth } from "../services/auth";
import BusinessView from "./businessView";
import { FullscreenSpinner } from "../ui-elements/spinner";

export default function ProtectedLayout() {
    const outlet = useOutlet();

    const [authenticated, setAuthenticated] = useState(null);

    
    useEffect(() => {
        const status = checkAuth();
        status.then(() => {
            setAuthenticated(true);
        }).catch(() => {
            setAuthenticated(false);
        });
    }, []);

    if (authenticated === null) {
        return <div>
            <FullscreenSpinner />
        </div>
    }
    
      if (authenticated === false) {
        return <Navigate to="../login" />;
      }
    
      return (
        <BusinessView>
          {outlet}
        </BusinessView>
    );
};