import "./header.css";
import BackButton from "../common/backbutton";

export default function Header({backButton, override}) {
  return (
    <div id="header" style={{textAlign: "center"}}>
      <img
        src="/images/header-curve.svg"
        style={{
          position: "absolute",
          top: 30,
          left: "50%",
          opacity: 0.3,
          width: "100%",
          transform: "translateX(-50%)",
          maxWidth: "20em",
          zIndex: -3
        }}
        id="header-curve"
      />
      <div className="glow-div"/>
      <img 
        style={{
          maxWidth: "6em",
          position: "relative",
          zIndex: 1,
          transform: "translateY(.5em)"
        }}
        src="/images/logo.svg"
      />
      {
        backButton &&
        <BackButton override={override}/>
      }
    </div>
  );
}