import { useState, useEffect } from "react";
import { formatPrice } from "../util/format";

export default function VariantsPrompt({product, close, addToShoppingCart}) {
  const [currentVariant, setCurrentVariant] = useState(null);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    if (!product) return;
    setVariants([...product.variants]);
  }, [product]);
  /* find first variant that is not yet selected */
  useEffect(() => {
    if(variants.length === 0) return;

    const currentVariant = variants.find(variant => !variant.chosen);
    
    //All variants chosen
    if(!currentVariant) {
      addToShoppingCart(product, variants.map(variant => variant.chosen));
      close();
      return;
    }
    setCurrentVariant(currentVariant);
  }, [variants]);

  if(!currentVariant) return null;

  const selectedVariantsPrice = variants.reduce((acc, variant) => {
    if(!variant.chosen) return acc;
    return acc + variant.chosen.price;
  }, 0);

  return (
    <div style={{width: "10em"}}>
      <p>{currentVariant.title}</p>
      {
        currentVariant.options.map(option => (
          <button
            key={option._id}
            onClick={() => {
              setVariants(variants.map(variant => {
                if (variant.title === currentVariant.title) {
                  return {
                    ...variant,
                    chosen: option
                  };
                }
                return variant;
              }));
            }}
          >
            <h3 style={{margin: "0.3em"}}>
              {
                option.name
              } ({
                option.price && option.price !== 0 ?
                formatPrice(product.price + (product.split_tax?.price || 0) + selectedVariantsPrice + option.price) : formatPrice(product.price + (product.split_tax?.price || 0) + selectedVariantsPrice)
              })
            </h3>
            <p style={{margin: 0, fontWeight: "normal"}}>
              {option.description}
            </p>
          </button>
        ))
      }
    </div>
  );
}