export const ORDER_STATUS = {
    NOT_ACCEPTED: "NOT_ACCEPTED",
    ACCEPTED: "ACCEPTED",
    READY: "READY",
    DELIVERED: "DELIVERED",
    REFUNDED: "REFUNDED",
};

export const SHOPPING_CART_STATUS = {
    UNORDERED: "UNORDERED",
    ORDERED: "ORDERED",
};