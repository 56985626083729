import {useState, useEffect} from "react";
import {Icon} from "./basic-ui";
import "./theme-button.css";

export default function ThemeButton({icon, onClick, title, style, disabled, loading, type = "button"}) {
  if(loading) {
    disabled = true;
  }
  return (
    <button
      type={type}
      className={`theme-button rotate-on-hover ${loading ? "theme-button-loading" : ""}`}
      style={{
        width: "3em",
        height: "3em",
        ...style
      }}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      <div style={{marginTop: "0.2em"}}>
        <Icon icon={icon} s={"1.4em"}/>
      </div>
    </button>
  );
}

export function PlusMinusButton({add, remove, amount}) {
  const decreaseCount = (e) => {
    e.stopPropagation();
    remove();
  }
  const increaseCount = (e) => {
    e.stopPropagation();
    add();
  }

  return (
    <div className={`theme-button ${amount !== 0 ? "big-theme-button" : ""}`}>
      <div>
        {
          amount > 0 ?
            <>
              <div
                onClick={decreaseCount}
                style={{
                  display: "inline-block",
                  textAlign: "center"
                }}
                className="plus-minus-fade-in"
              >
                -
              </div>
              <div className="plus-minus-fade-in-small">
                <span>
                  {amount}
                </span>
              </div>
            </> :
            null
        }
        <div
          onClick={increaseCount}
          style={{
            display: "inline-block",
            width: "32px",
            height: "32px",
            textAlign: "center"
          }}
        >
          +
        </div>
      </div>
    </div>
  );
}