import useTranslate from "../util/dictionary";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Mobile, Container, Footer, Icon, IconText } from "../ui-elements/basic-ui";
import { useLocalStorageState } from "../hooks/localStorageHook";
import { SHOPPING_CART_STATUS } from "../util/constants";
import { isTodayAccountingMidnight } from "../util/functions";

export default function RestaurantSelect() {
    const navigate = useNavigate();
    const tr = useTranslate();
    const lang = "fi";

    const [id, setId] = useState("");

    function navigateToOrderPage() {
        navigate(`/${id.toLowerCase()}`);
    }

    //If there is a made order in local storage, navigate to order page
    const [shoppingCart] = useLocalStorageState("shoppingCart", {});

    useEffect(() => {
      const isFromToday = isTodayAccountingMidnight(shoppingCart.date);
      if(shoppingCart.status === SHOPPING_CART_STATUS.ORDERED && isFromToday) {
          navigate("/order/" + shoppingCart.restaurantId + "/" + shoppingCart.order);
      }
    }, [shoppingCart]);

    /*return (
      <Mobile>
        <img src="images/logo.png"></img>
        <Container
          style={{
            width: "6em",
            height: "6em",
            alignContent: "center"
          }}
        />
        <Button
          onClick={navigateToOrderPage}
          variant="contained"
          sx={{ mt: 1 }}
          fullWidth={true}
        >
          <Icon icon="camera" w s={32} style={{marginTop: "1em"}}/>
          <p className="small-text">Kerro sijaintisi skannaamalla QR-koodi</p>
        </Container>
        <Button>
          <IconText icon="camera" s={16} text="Tai käytä GPS-paikannusta"/>
        </Button>
        <TextInput
          style={{textAlign: "center", height: "4em"}}
          value={id}
          onChange={e => setId(e.target.value)}
        >
          Kirjoita ravintolan nimi / koodi
        </TextInput>
        <Footer>
          <a href="#">Mikä on jonos.io</a> | <a href="#">Tietosuojaseloste</a>
        </Footer>
      </Mobile>
    );*/

    return (
        <>
            <Mobile>
                <img src="/images/logo.svg"></img><br/>
                <img src="/images/graphic_left.svg" style={{position: "absolute", left: 0, top: "30%", width: "40%", zIndex: -1, opacity: 0.8}}/>
                <img src="/images/graphic_right.svg" style={{position: "absolute", right: 0, bottom: "0%", width: "40%", zIndex: -1, opacity: 0.8}}/>
                <label>{tr("input_restaurant_id")}</label>
                <form
                  onSubmit={navigateToOrderPage}
                  style={{padding: "0.5em"}}
                >
                  <input
                      value={id}
                      onChange={e => setId(e.target.value)}
                      style={{
                          width: "100%",
                          textTransform: "uppercase",
                          textAlign: "center",
                          fontSize: "1.4em",
                          padding: "0.5em",
                          color: "#fff",
                          height: "2em"
                      }}
                  />
                  <button type="submit">
                      <b>{tr("to_order")}</b>
                  </button>
                </form>                
            </Mobile>
            <div style={{position: "absolute", left: 0, bottom: 0}}>
                <button
                    style={{color: "#666", backgroundColor: "#050505"}}
                    onClick={() => navigate("/restaurant")}>
                        {tr("for_restaurant_owner")}
                </button>
            </div>
        </>
    );
}