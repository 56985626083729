import businessUserService from "./business/userService";

export default function useAuth() {
    const token = localStorage.getItem("token");
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
}

export function getToken() {
    return localStorage.getItem("token");
}

export function checkAuth() {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
        if (token) {
            businessUserService.validateToken().then((res) => {
                resolve(true);
            }).catch((err) => {
                reject(err);
            });
        } else {
            reject(false);
        }
    });
}
