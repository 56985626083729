import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import OrganisationForm from "./formcontroller";
import { useNavigate } from 'react-router-dom';
import useTranslate from "../../util/dictionary";
import Link from '@mui/material/Link';
import organisationService from "../../services/business/organisationService";

export default function CreateOrganisation() {

    const [errors, setErrors] = useState({});
   
    const navigateTo = useNavigate();
    const tr = useTranslate();

    const createOrganisation = async (data) => {
        try {
            const res = await organisationService.create(data);
            navigateTo("/restaurant");
        } catch (e) {
            console.log("ERROR ", e);
            const errorData = e.response.data;

            if (typeof errorData === "object") {
                if (Array.isArray(errorData)) {
                    setErrors(errorData.reduce((acc, error) => ({ ...acc, ...error }), {}));
                } else {
                    setErrors(errorData);
                }

            } else {
                setErrors({ general: errorData });
            }
        }
    };

    return (
        <>
            <OrganisationForm
                title="create_organisation"
                handleSubmit={createOrganisation}
                errors={errors}
                addDefaultCounter={true}
            />
            <Grid container>
                <Link href="#" color="secondary">
                    {tr("join_organisation")}
                </Link>
            </Grid>
        </>
    );
}