import { useState } from 'react';
import { Input, IconText } from '../../ui-elements/basic-ui';
import ThemeButton from '../../ui-elements/theme-button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import uuid from 'react-uuid';
import useTranslate from "../../util/dictionary";
import CounterForm from './counterForm';

export default function OrganisationForm({ title, handleSubmit, errors, defaultValues = {}, addDefaultCounter }) {
    const tr = useTranslate();
    const [users, setUsers] = useState(defaultValues.users ? defaultValues.users : []);
    const [counters, setCounters] = useState(defaultValues.counters ? defaultValues.counters : []);
    const [countersToDelete, setCountersToDelete] = useState([]);

    const preSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        handleSubmit({
            organisationName: data.get("organisationName"),
            barName: data.get("barName"),
            businessId: data.get("businessId"),
            users: users.map(user => user.email).filter(email => email !== ""),
            counters,
            countersToDelete
        });
    };

    return (
        <>
            <div style={{maxWidth: "20em", margin: "auto"}}>
                <h3>{tr(title)} </h3>
                <form onSubmit={preSubmit}>
                    <Input
                        required
                        id="organisationName"
                        name="organisationName"
                        autoComplete="organization"
                        label="organisation_name"
                        error={errors["organisation_name"]}
                        defaultValue={defaultValues.organisationName || ""}
                    />
                    <Input
                        required
                        name="businessId"
                        label="business_id"
                        id="businessId"
                        autoComplete=""
                        error={errors["businessId"]}
                        defaultValue={defaultValues.businessId || ""}
                    />
                    <CounterForm
                        counters={counters}
                        setCounters={setCounters}
                        countersToDelete={countersToDelete}
                        setCountersToDelete={setCountersToDelete}
                        defaultValues={defaultValues}
                        errors={errors}
                        addDefaultCounter={addDefaultCounter}
                    />
                    <Users users={users} setUsers={setUsers} errors={errors} />
                    <br />
                    <button type="submit">
                        {tr("save")}
                    </button>
                </form>
            </div>
        </>
    );
}

function Users({ users, setUsers, errors }) {
    const tr = useTranslate();
    const [open, setOpen] = useState(users.length > 0);

    const addUser = () => {
        setUsers([...users, { _id: uuid(), name: "" }]);
    };

    if (!open) {
        return (
            <button
                onClick={() => {
                    setOpen(true);
                    addUser();
                }}
            >
                {tr("add_users_to_organisation")}
            </button>
        );
    }

    return (
        <>
            <h6>{tr("add_other_emails")}:</h6>
            {
                users.map((user, i) => (
                    <div key={user._id}>
                        <div style={{display: "inline-block", width: "80%"}}>
                            <Input
                                onChange={(e) => setUsers(users.map(u => u._id === user._id ? { ...u, email: e.target.value } : u))}
                                value={user.email}
                                required
                                name="useremail"
                                label="email"
                                id="businessId"
                                autoComplete=""
                                error={errors?.["tmpUserEmail"]}
                            />
                        </div>
                        <div style={{display: "inline-block", width: "20%"}}>
                            <ThemeButton
                                icon="delete"
                                sx={{ mt: 2, mb: 2, ml: 1, height: 55 }}
                                color="error"
                                onClick={() => setUsers(users.filter(u => u._id !== user._id))}
                            />
                        </div>
                    </div>
                ))
            }
            <button onClick={addUser}>
                <IconText
                    icon="plus"
                    text={tr("add_user")}
                    black
                    center
                />
            </button>
            {
                errors && errors["users"] ?
                    <Alert severity="error">{tr(errors["users"])}</Alert> :
                    null
            }
        </>
    );
}

