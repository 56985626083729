import axios from "axios";
import useAuth from "../auth";

const baseUrl = "/api/business/user";

const businessUserService = {
    login: async (data) => {
        return (await axios.post(baseUrl + "/login", data))?.data;
    },

    signup: async (data) => {
        return (await axios.post(baseUrl + "/signup", data))?.data;
    },

    update: async (data) => {
        return (await axios.put(baseUrl + "/update", data, useAuth()))?.data;
    },

    validateToken: async () => {
        return (await axios.get(baseUrl + "/token-is-valid", useAuth()))?.data;
    },
    get: async () => {
        return (await axios.get(baseUrl + "/get", useAuth()))?.data;
    }
};

export default businessUserService;