import { useSearchParams } from 'react-router-dom';
import tr from "../util/dictionary";
import { FullscreenSpinner } from "../ui-elements/spinner";
import { Mobile } from "../ui-elements/basic-ui";
import Header from "../ui-elements/header";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import customerService from "../services/customerService";
import { useLocalStorageState } from "../hooks/localStorageHook";
import ErrorPage from "./errorPage";

export default function OrderCallbackPage() {
    const [searchParams] = useSearchParams();
    const { restaurantId, orderId } = useParams();
    const navigate = useNavigate();

    const [shoppingCart, setShoppingCart] = useLocalStorageState("shoppingCart", {});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    const finishOrder = async () => {
        customerService.finishOrder(Object.fromEntries([...searchParams]))
            .then((res) => {
                setShoppingCart({ ...shoppingCart, ...res });
                navigate(`/order/${restaurantId}/${orderId}`);
            })
            .catch((e) => {
                setError(e.response.data);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    useEffect(() => {
        finishOrder()
    }, [])

    if (!!isLoading) {
        return <FullscreenSpinner />
    }

    return (
        <Mobile footerGraphics pad>
            <Header />
            {error && <ErrorPage error={error} navigateTo={shoppingCart.restaurantId} showGraphics={false} />}
        </Mobile>
    );
}