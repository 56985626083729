import axios from "axios";
import useAuth from "../auth";

const baseUrl = "/api/business/table";

const tableService = {
    create: async (data) => {
        return (await axios.post(baseUrl, data, useAuth()))?.data;
    },
    delete: async (id, counterId) => {
        return (await axios.delete(`${baseUrl}/${counterId}/${id}`, useAuth()))?.data;
    },
    edit: async (id, data) => {
        return (await axios.put(`${baseUrl}/${id}`, data, useAuth()))?.data;
    },
};

export default tableService;