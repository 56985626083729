import useTranslate from "../util/dictionary";
import {FullscreenSpinner} from "../ui-elements/spinner";
import Modal from "../ui-elements/modal";
import Header from "../ui-elements/header";
import { Mobile, Icon, IconText } from "../ui-elements/basic-ui";
import { useState, useEffect, useRef } from "react";
import customerService from "../services/customerService";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import ProductCard from "./productCard";
import { useLocalStorageState } from "../hooks/localStorageHook";
import { SHOPPING_CART_STATUS } from '../util/constants';
import { calculateTotalFromShoppingCart, getTimeToClosing, organizeByCategories, getTranslatedTag, isTodayAccountingMidnight } from "../util/functions";
import { formatPrice } from "../util/format";
import { useLanguageContext } from "../context/language";
import VariantsPrompt from "./variantsPrompt";

export default function RestaurantPage(props) {

    const { restaurantId } = useParams();
    
    const { language } = useLanguageContext();
    const tr = useTranslate();
    const [query] = useSearchParams();
    const navigate = useNavigate();

    const [tagSelection, setTagSelection] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);

    const [restaurant, setRestaurant] = useState();
    const [productsByTags, setProductsByTags] = useState([]);
    const [shoppingCart, setShoppingCart] = useLocalStorageState("shoppingCart", {});
    const [error, setError] = useState();
    
    const [lastCall, setLastCall] = useState(null);

    const containerRefs = useRef([]);
    const [variantsPrompt, setVariantsPrompt] = useState(null);
    const [modalProduct, setModalProduct] = useState(null);

    //We zoom the container if we have a scenario
    //where we don't have a part of the next product visible
    //To make sure that user sees it's scrollable
    const [zoom, setZoom] = useState(1);
    useEffect(() => {
        const handleResize = () => {
            const emSize = window.innerWidth / parseFloat(
                getComputedStyle(
                  document.querySelector('body')
                )['font-size']);
            const screenMargins = 0.5;
            const productMargins = 0.5;
            const productWidth = 12;

            let sizeLeft = emSize - screenMargins;

            //if size is too small, scale until the Other product is slightly visible
            const productSize = productWidth + productMargins;
            const ratio = sizeLeft / productSize;

            if(ratio % 1 > 0.85) {
                setZoom(ratio / (Math.ceil(ratio) + .2 + ratio * .1));
            } else if(ratio % 1 < 0.3) {
                setZoom(ratio / (Math.floor(ratio) + .2 + ratio * .1));
            } else {
                setZoom(1);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if(restaurant) {
                const call = getTimeToClosing(restaurant);
                if(call === "0:00") {
                    clearInterval(interval);
                    navigate("/error/closed");
                }
                setLastCall(call);
            }
        }, 1000);

        return () => clearInterval(interval);

    }, [restaurant]);

    const handleWheelScroll = (e, i) => {
        containerRefs[i].scrollLeft += e.deltaY * 2;
    };

    const updateRestaurant = async () => {
        try {
            const restaurant = await customerService.getOrderPage(restaurantId);
            setRestaurant(restaurant);
            setProductsByTags(organizeByCategories(restaurant.products));
            setError(null);
        } catch (e) {
            console.log(e);
            setRestaurant(null);
            setProductsByTags({});
            setError(e.response.data);
        }
    }

    useEffect(() => {
        updateRestaurant();
    }, [restaurantId]);
     
    useEffect(() => {
        //If the previous shopping cart is for another business, clear it first
        //Or if the shopping cart is from another day, clear it first as well
        //Account for midnight though
        //If the shopping cart is ordered move to the order page

        const isFromToday = isTodayAccountingMidnight(shoppingCart.date);
        if (
            (
                shoppingCart.restaurantId &&
                shoppingCart.restaurantId !== restaurantId
            ) || (
                shoppingCart.date && !isFromToday
            )
        ) {
            setShoppingCart({items: []});
        } else {
            if(shoppingCart.status === SHOPPING_CART_STATUS.ORDERED) {
                navigate("/order/" + shoppingCart.restaurantId + "/" + shoppingCart.order);
            }
        }
    }, [shoppingCart]);

    const addToShoppingCart = (product) => {
        if(product.variants && product.variants.length > 0) {
            setVariantsPrompt({...product});
        } else {
            actuallyAddToShoppingCart(product);
        }
    }

    const actuallyAddToShoppingCart = (product, variant = undefined) => {
        let updatedShoppingCart = {...shoppingCart};

        updatedShoppingCart.restaurantId = restaurantId;
        updatedShoppingCart.date = new Date().toDateString();
        updatedShoppingCart.status = SHOPPING_CART_STATUS.UNORDERED;

        //If the shopping cart already has this product, increase the amount
        if (shoppingCart.items) {
            const index = shoppingCart.items.findIndex(item => item.product._id === product._id);

            if(index !== -1) {
                updatedShoppingCart.items[index].amount++;
                if(variant) {
                    updatedShoppingCart.items[index].variants.push(variant);
                }
            } else {
                updatedShoppingCart.items.push({ product, amount: 1, variants: variant ? [variant] : undefined });
            }
        } else {
            updatedShoppingCart.items = [{ product, amount: 1, variants: variant ? [variant] : undefined }];
        }

        setShoppingCart(updatedShoppingCart);

    }

    const removeFromShoppingCart = (product) => {
        if(shoppingCart.items.find(item => item.product._id === product._id).amount > 1) {
            const item = shoppingCart.items.find(item => item.product._id === product._id);
            item.amount--;
            if(item.variants && item.variants.length > 0)
                item.variants.pop();
            setShoppingCart({...shoppingCart, items: shoppingCart.items});
        } else {
            setShoppingCart({...shoppingCart, items: shoppingCart.items.filter(item => item.product._id !== product._id)});
        }
    }

    if (restaurant && !restaurant.open) {
        return (
            <Mobile>
                <h3>{tr("closed")}</h3>
                <button onClick={updateRestaurant}>
                    {tr("refresh")}
                </button>
            </Mobile>
        );
    }

    const allTags = Object.values(productsByTags);
    const currentTagsUnsorted = selectedTag ? [selectedTag] : allTags;
    const currentTags = currentTagsUnsorted.sort((a, b) => a.tag.priority - b.tag.priority);

    return (
        <>
            {!restaurant && !error ?
                <div>
                    <FullscreenSpinner />
                </div>
                :
                null
            }
            {error && <ErrorPage error={error} />}
            {restaurant &&
                <div style={{textAlign: "left"}}>
                    <Header/>
                    {
                        allTags.length > 1 &&
                        <div
                            onClick={() => setTagSelection(!tagSelection)}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "24px",
                                margin: "auto",
                                maxWidth: "40em",
                                cursor: "pointer"
                            }}
                        >
                            <h3
                                style={{
                                    margin: 0,
                                    fontWeight: "normal",
                                    textAlign: currentTags.length > 1 ? "left" : "center"
                                }}
                            >
                                Menu
                            </h3>
                            <Icon icon="menu" black style={{float: "right"}}/>
                        </div>
                    }
                    <div className="menu">
                    {
                        tagSelection ?
                            <TagSelection
                                tags={allTags}
                                setTagSelection={setTagSelection}
                                setSelectedTag={setSelectedTag}    
                                language={language}                        
                            /> :
                            <>
                                {
                                    selectedTag &&
                                        <div
                                            style={{
                                                color: "white",
                                                fontStyle: "italic",
                                                fontSize: "0.9em",
                                                paddingLeft: "24px",
                                                marginBottom: "1em",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                setSelectedTag(null);
                                                setTagSelection(false);
                                            }}
                                        >
                                            <IconText
                                                yellow
                                                icon="arrow-left"
                                                s={15}
                                                text={tr("back_to_all_categories")}
                                            />
                                        </div>
                                }
                                {
                                    currentTags.map((data, index) => (
                                        <div key={data.tag.name}>
                                            {
                                                (currentTags.length > 1 || selectedTag) &&
                                                <h4 
                                                    className="highlight" 
                                                    style={{
                                                        marginTop: "4px",
                                                        marginLeft: "24px",
                                                        marginBottom: ".5em",
                                                        fontSize: "0.9em"
                                                    }}
                                                >
                                                    {getTranslatedTag(data.tag, language)}
                                                </h4>
                                            }
                                            <div
                                                className="product-list"
                                                onWheel={e => handleWheelScroll(e, index)}
                                                ref={el => containerRefs[index] = el}                                    
                                                style={{
                                                    zoom: zoom,
                                                    ...(
                                                        currentTags.length === 1 ?
                                                        {
                                                            flexWrap: "wrap",
                                                            justifyContent: "center",
                                                            textAlign: "left"
                                                        } :
                                                        {}
                                                    )
                                                }}
                                            >
                                                {
                                                    productsByTags[data.tag.name].products.map((product, index) =>
                                                        <ProductCard
                                                            product={product}
                                                            key={index}
                                                            style={{
                                                                display: "inline-block",
                                                                margin: "0.5em"
                                                            }}
                                                            openModal={(product) => setModalProduct(product)}
                                                            amount={(shoppingCart.items ? shoppingCart.items.find(item => item.product._id === product._id)?.amount : null) || 0}
                                                            variants={shoppingCart.items ? shoppingCart.items.find(item => item.product._id === product._id)?.variants || [] : []}
                                                            addAction={() => addToShoppingCart(product)}
                                                            removeAction={() => removeFromShoppingCart(product)}
                                                        />
                                                    )
                                                }
                                                {
                                                    // this will align odd amount of items correctly
                                                    currentTags.length === 1 && restaurant.products.length % 2 === 1 &&
                                                    <div style={{minWidth: "13em"}}/>
                                                }
                                            </div>
                                        </div>
                                    )
                                )}
                            </>
                        }
                    </div>
                    {/*Some space to bottom*/}
                    <div
                        style={{width: "90%", height: "5em", backgroundColor: "var(--dark)"}}
                        id="space-taker"
                    />
                    <p style={{color: "transparent"}}>placeholder</p>
                    {
                        shoppingCart.items && shoppingCart.items.length > 0 &&
                            <div id="checkout-button">
                                <button
                                    style={{marginTop: "1em", fontWeight: "normal"}}
                                    onClick={() => navigate("../shoppingcart" + (query ? `?${query.toString()}` : ""))}
                                >
                                    <span><b>{tr("to_checkout")}</b></span><br/>
                                    {tr("total")} {formatPrice(calculateTotalFromShoppingCart(shoppingCart))}
                                </button>
                            </div>
                    }
                    {
                        lastCall !== null && 
                        <div id="last-call">
                            <p>{tr("last_call")}: {lastCall}</p>
                        </div>
                    }
                    <Modal
                        open={modalProduct !== null}
                        onClose={() => setModalProduct(null)}
                        style={{
                            backgroundColor: "var(--medium)",
                            paddingTop: "3em"
                        }}
                    >
                        <ProductCard
                            product={modalProduct}
                            lineAmt={15}
                            fontSize={1.3}
                            style={{
                                width: "100%",
                                height: "27em",
                                maxWidth: "30em",
                                maxHeight: "90%"
                            }}

                            amount={(shoppingCart.items && modalProduct ? shoppingCart.items.find(item => item.product._id === modalProduct._id)?.amount : null) || 0}
                            variants={(shoppingCart.items && modalProduct ? shoppingCart.items.find(item => item.product._id === modalProduct._id)?.variants : null) || []}                        
                            setProductsInShoppingCart={setShoppingCart}
                            addAction={() => addToShoppingCart(modalProduct)}
                            removeAction={() => removeFromShoppingCart(modalProduct)}
                        />
                    </Modal>
                    <Modal
                        open={variantsPrompt !== null}
                        onClose={() => setVariantsPrompt(null)}
                        style={{
                            backgroundColor: "var(--medium)",
                            paddingTop: "3em"
                        }}
                    >
                        <VariantsPrompt
                            product={variantsPrompt}
                            close={() => setVariantsPrompt(null)}
                            addToShoppingCart={actuallyAddToShoppingCart}
                        />
                    </Modal>
                </div>
            }
        </>  
    );
}

function ErrorPage({ error }) {

    const tr = useTranslate();
    const navigate = useNavigate();

    function navigateToHome() {
        navigate(`/`);
    }

    return (
        <Mobile>
            <p>{tr(error)}</p>
            <button
                onClick={navigateToHome}
            >
                {tr("return")}
            </button>
        </Mobile>
    );
}

function TagSelection({tags, setTagSelection, setSelectedTag, language}) {
    return (
        <div id="tag-selection">
            <div>
                {
                    tags.map((data) => (
                        <div
                            className="tag-listing"
                            key={data.tag.name}
                            onClick={() => {
                                setTagSelection(false);
                                setSelectedTag(data);
                            }}
                        >
                            <p>{getTranslatedTag(data.tag, language)}</p>
                            <Icon icon={"categories/" + data.tag.icon} yellow/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
