import { useState, useEffect, useRef } from 'react';
import catcher from '../util/catcher';
import Product, { ProductForm } from './product';
import productService from '../services/business/productService';
import Modal from "../ui-elements/modal";
import TagModal from "./tagModal";
import ThemeButton from "../ui-elements/theme-button";
import { Input, Icon } from "../ui-elements/basic-ui";
import useTranslate from '../util/dictionary';
import { useLanguageContext } from '../context/language';
import organisationService from '../services/business/organisationService';
import { getTranslatedTag, organizeByCategories } from '../util/functions';
import { ReactSortable } from "react-sortablejs";
import { toastError } from '../util/toast';


export default function ProductController() {
    const tr = useTranslate();
    const { language } = useLanguageContext();
    
    const [addFormOpen, setAddFormOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [products, setProducts] = useState([]);
    const [counters, setCounters] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagModalOpen, setTagModalOpen] = useState(false);
    const [search, setSearch] = useState("");

    useEffect(() => {
        const getCounters = async () => {
            const organisation = await organisationService.get();
            setCounters(organisation.counters);
            setTags(organisation.tags);
        };
        getCounters();
    }, [
    ]);

    useEffect(() => {
        catcher(async () => {
            const products = await productService.get();
            setProducts(products);
        });
    }, []);

    const addProduct = async (productData) => {
        try {
            const res = await productService.create(productData);
            setProducts(res);
            setAddFormOpen(false);
            document.documentElement.scrollTop = 0;

        } catch (e) {
            console.log("ERROR ", e);
            const errorData = e.response.data;

            if (typeof errorData === "object") {
                if (Array.isArray(errorData)) {
                    setErrors(errorData.reduce((acc, error) => ({ ...acc, ...error }), {}));
                } else {
                    setErrors(errorData);
                }
            } else {
                setErrors({ general: errorData });
            }
        }
    };

    const productsInCategories = organizeByCategories(products);

    const reorganizeProductsDragAndDrop = (tag) => {
        return async (newOrderFull) => {

            if(!productsInCategories[tag]) return;

            const old = [...products]
            const oldOrder = productsInCategories[tag].products.map(product => product._id);
            const newOrder = newOrderFull.map(item => item._id);

            //if old and new order aren't the same
            if(JSON.stringify(oldOrder) !== JSON.stringify(newOrder)) {
                try {
                    setProducts(newOrderFull);
                    const newProducts = await productService.reorganizeProducts({newOrder});
                    setProducts(newProducts);
                } catch (e) {
                    toastError(tr("something_went_wrong"));
                    setProducts(old);
                }
            }
        };
    };

    const reorganizeProductsArrowKeys = async (product, direction) => {
        const oldOrder = [...products];
        const movingElementIdx = oldOrder.findIndex(p => p._id.toString() === product);

        const newOrder = [...oldOrder];  


        if(movingElementIdx + direction < 0 && movingElementIdx + direction >= newOrder.length)
            return;

        newOrder.splice(movingElementIdx, 1);
        newOrder.splice(movingElementIdx + direction, 0, products.find(p => p._id.toString() === product));

        setProducts(newOrder);
        try {
            setProducts(newOrder);
            const newProducts = await productService.reorganizeProducts({newOrder: newOrder.map(p => p._id)});
            setProducts(newProducts);
        } catch (e) {
            toastError(tr("something_went_wrong"));
            setProducts(oldOrder);
        }
    };


    return (
        <>
            {
                !addFormOpen ?
                    <>
                        <div style={{display: "flex"}}>
                            <div style={{width: "7em", marginLeft: ".5em"}}>
                                <button
                                    onClick={() => setAddFormOpen(true)}
                                >
                                    {tr("create_product")}
                                </button>
                            </div>
                            <div style={{width: "7em", marginLeft: ".5em"}}>
                                <button onClick={() => setTagModalOpen(true)}>
                                    {tr("view_tags")}
                                </button>
                            </div>
                            <div style={{display: "flex", width: "9em", marginLeft: ".5em", alignItems: "center"}}>
                                <Icon icon="search" style={{marginTop: ".5em"}}/>
                                <input
                                    type="text"
                                    placeholder={tr("search")}
                                    style={{
                                        height: "3.8em",
                                        marginTop: "1.1em",
                                        marginLeft: "0.5em"
                                    }}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                />
                            </div>
                        </div>
                        <div style={{textAlign: "left", marginTop: "1em"}}>
                            {
                                search.length > 0 ?
                                    <>
                                    <h4
                                        className="highlight"
                                        style={{
                                            marginTop: "1em",
                                            marginLeft: "1em",
                                            marginBottom: ".5em",
                                            fontSize: "0.9em"
                                        }}
                                    >{tr("search")}: {search}...</h4>
                                        <div style={{display: "flex", flexWrap: "wrap", marginLeft: "1em"}}>
                                        {
                                            products.filter(product => product.name.toLowerCase().includes(search.toLowerCase())).map(product =>
                                                <Product
                                                    key={product._id}
                                                    product={product}
                                                    setProducts={setProducts}
                                                    allTags={tags}
                                                    allCounters={counters}
                                                    style={{
                                                        display: "inline-block",
                                                        margin: "0.5em"
                                                    }}
                                                    disableReorder={true}
                                                />
                                            )
                                        }
                                        </div>
                                    </>
                                :
                                tags.concat({name: "Other", translations: {"fi": "Muut"}})
                                .map(tag => {
                                    if(
                                        !productsInCategories?.[tag.name]?.products ||
                                        productsInCategories?.[tag.name]?.products.length === 0
                                    ) {
                                        return null;
                                    } else {
                                        return (
                                            <div key={tag._id} style={{minHeight: "10em"}}>
                                                <h4
                                                    className="highlight" 
                                                    style={{
                                                        marginTop: "4px",
                                                        marginLeft: "24px",
                                                        marginBottom: ".5em",
                                                        fontSize: "0.9em"
                                                    }}
                                                >
                                                    {tags.length !== 0 ? getTranslatedTag(tag, language) : ""}
                                                </h4>
                                                <div>
                                                    <ReactSortable
                                                        list={productsInCategories?.[tag.name]?.products || []}
                                                        setList={reorganizeProductsDragAndDrop(tag.name)}
                                                        style={{display: "flex", flexWrap: "wrap", marginLeft: "1em"}}
                                                        >
                                                        {productsInCategories?.[tag.name]?.products.map((product) => (
                                                            <div key={product._id}>
                                                                <Product
                                                                    product={product}
                                                                    setProducts={setProducts}
                                                                    allTags={tags}
                                                                    allCounters={counters}
                                                                    style={{
                                                                        display: "inline-block",
                                                                        margin: "0.5em"
                                                                    }}
                                                                    reorganize={reorganizeProductsArrowKeys}
                                                                    />
                                                            </div>
                                                        ))}
                                                    </ReactSortable>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                            
                        </div>
                    </>
                    :
                    <div style={{ backgroundColor: "var(--dark)", padding: 16, maxWidth: "20em", margin: "auto" }}>
                        <button onClick={() => setAddFormOpen(false)} style={{width: "8em"}}>{tr("back")}</button>
                        <ProductForm
                            product={null}
                            handleSubmit={addProduct}
                            errors={errors}
                            submitText="create_product"
                            allTags={tags}
                            allCounters={counters}
                        />
                    </div>
            }
            {
                errors["general"] ?
                    <Alert severity="error">{tr(errors["general"])}</Alert> :
                    null
            }
            <TagModal
                tagModalOpen={tagModalOpen}
                setTagModalOpen={setTagModalOpen}
                tags={tags}
                setTags={setTags}
            />
        </>
    );
}

