import { ThemeProvider } from "@emotion/react";
import LanguageProvider from "./context/language";
import LoadingProvider from "./context/loading";
import RestaurantSelect from "./customer/restaurantSelect";
import RestaurantPage from "./customer/restaurantPage";
import BusinessLogin from "./business/login";
import BusinessSignup from "./business/signup";
import BusinessMain from "./business/main";
import Logout from "./business/logout";
import BusinessOrderController from "./business/ordercontroller";
import CreateOrganisation from "./business/organisation/create";
import EditOrganisation from "./business/organisation/edit";
import ProductController from "./business/productcontroller";
import OrderPage from "./customer/orderPage";
import NotificationSettings from "./customer/notificationSettings";
import ProtectedLayout from "./business/protectedLayout";
import OrderCallbackPage from "./customer/orderCallbackPage";
import ErrorPage from "./customer/errorPage";
import './styles.css'
import './BusinessApp.css';
import './CustomerApp.css';
import { theme } from "./util/theme";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Settings from "./business/userSettings";
import ShoppingCart from "./customer/shoppingCart";
import OrderHistoryController from "./business/orderHistory";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/*business*/}
      <Route path="/login" element={<BusinessLogin />} />
      <Route path="/signup" element={<BusinessSignup />} />

      <Route path="/restaurant" element={<ProtectedLayout />}>
        <Route path="/restaurant/" element={<BusinessMain />} />
        <Route path="/restaurant/orders">
          <Route path=":counterPasscode" element={<BusinessOrderController />} />
          <Route path=":counterPasscode/history" element={<OrderHistoryController />} />
        </Route>
        <Route path="/restaurant/organisation/create" element={<CreateOrganisation />} />
        <Route path="/restaurant/organisation/edit" element={<EditOrganisation />} />
        <Route path="/restaurant/products" element={<ProductController />} />
        <Route path="/restaurant/settings" element={<Settings />} />
        <Route path="/restaurant/logout" element={<Logout />} />
      </Route>

      {/*customer*/}
      <Route path="/" element={<RestaurantSelect />} />,
      <Route path="/notifications" element={<NotificationSettings />} />,
      <Route path="/:restaurantId" element={<RestaurantPage />} />,
      <Route path="/payment/:restaurantId/:orderId" element={<OrderCallbackPage />} />,
      <Route path="/order/:restaurantId/:orderId" element={<OrderPage />} />,
      <Route path="/shoppingcart" element={<ShoppingCart />} />,
      <Route path="/error/:error" element={<ErrorPage/>} />,
    </>
  )
);

const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js', { scope: './' })
      .catch()
  }
}
function App() {
  registerServiceWorker();

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <LanguageProvider>
            <RouterProvider router={router} />
            <ToastContainer
              position="bottom-right"
              theme="light"
              hideProgressBar={false}
              pauseOnHover
            />
        </LanguageProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
