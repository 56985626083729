import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import useTranslate from "../util/dictionary";
import { useNavigate } from 'react-router-dom';
import businessUserService from "../services/business/userService";

/*
* Based on MUI own template
* Don't use as a style guide
*/

export default function SignUp() {
  const navigate = useNavigate();
  const tr = useTranslate();

  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);


    if(data.get("password") !== data.get("password_again")) {
      setErrors({password_again: "passwords_dont_match"});
      return;
    }

    try {
      const res = await businessUserService.signup({
        username: data.get("username"),
        email: data.get('email'),
        password: data.get('password'),
        name: data.get("name")
      });
      navigate("/restaurant");
    } catch (e) {
      const errorData = e.response.data;

      if(typeof errorData === "object") {
        if(Array.isArray(errorData)) {
          setErrors(errorData.reduce((acc, error) => ({...acc, ...error}), {}));
        } else {
          setErrors(errorData);
        }
      
      } else {
        setErrors({general: errorData});
      }
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {tr("create_account")}
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                color="dark"
                required
                id="username"
                label={tr("username")}
                name="username"
                error={errors["username"]}
                helperText={tr(errors["username"])}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="dark"
                required
                id="email"
                label={tr("email")}
                name="email"
                autoComplete="email"
                error={errors["email"]}
                helperText={tr(errors["email"])}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="dark"
                required
                name="password"
                label={tr("password")}
                type="password"
                id="password"
                autoComplete="new-password"
                error={errors["password"]}
                helperText={tr(errors["password"])}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="dark"
                required
                name="password_again"
                label={tr("password_again")}
                type="password"
                id="password_again"
                autoComplete="new-password"
                error={errors["password_again"]}
                helperText={tr(errors["password_again"])}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="dark"
                autoComplete="name"
                name="name"
                id="name"
                label={tr("name")}
                autoFocus
              />
            </Grid>
          </Grid>
          <Button type="submit">
            {tr("sign_up")}
          </Button>
          { 
            errors["general"] ?
              <Alert severity="error">{tr(errors["general"])}</Alert> :
              null
          }
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" onClick={() => navigate("/restaurant")} color="secondary">
                {tr("already_have_an_account")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}