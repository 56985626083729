import { useNavigate } from "react-router-dom";
import { Icon } from "../ui-elements/basic-ui";

export default function BackButton({override, style}) {
    
    const navigateTo = useNavigate();
    
    return (
        <button
            style={{backgroundColor: "inherit", justifyContent: "left", width: "5em", position: "absolute", top: "1em", left: 0, ...style}}
            onClick={() => override ? override() : navigateTo("../../restaurant")}
        >
            <Icon icon="return" s={30}/>
        </button>
    );
}