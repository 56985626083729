import axios from "axios";
import useAuth from "../auth";

const baseUrl = "/api/business/organisation";

const organisationService = {
    get: async () => {
        return (await axios.get(baseUrl, useAuth()))?.data;
    },

    create: async (data) => {
        return (await axios.post(baseUrl, data, useAuth()))?.data;
    },

    update: async (data) => {
        return (await axios.put(baseUrl, data, useAuth()))?.data;
    },

    generateUniqueCode: async () => {
        return (await axios.get(`${baseUrl}/code`, useAuth()))?.data;
    }
};

export default organisationService;