import { useState, useEffect } from 'react';
import organisationService from "../../services/business/organisationService";
import { useNavigate } from 'react-router-dom';
import OrganisationForm from "./formcontroller";
import { toastRestError, toastError } from '../../util/toast';
import useTranslate from '../../util/dictionary';

export default function EditOrganisation() {
    const tr = useTranslate();
    const [errors, setErrors] = useState({});
    const [defaultValues, setDefaultValues] = useState({ loading: true });
    const navigateTo = useNavigate();

    useEffect(() => {
        const fetchOrganisation = async () => {
            try {
                const res = await organisationService.get();
                setDefaultValues({
                    organisationName: res.name,
                    businessId: res.businessId,
                    users: res.users.map(user => ({ _id: user._id, email: user.user ? user.user.email : user.tmpUserEmail })),
                    counters: res.counters
                });
            } catch (e) {
                toastError(tr("loading_error"));
            }
        };
        fetchOrganisation();
    }, []);

    const editOrganisation = async (data) => {
        try {
            await organisationService.update(data);
            navigateTo("/restaurant");
        } catch (e) {
            const errorData = e.response.data;

            toastRestError(tr, e);

            if (typeof errorData === "object") {
                if (Array.isArray(errorData)) {
                    setErrors(errorData.reduce((acc, error) => ({ ...acc, ...error }), {}));
                } else {
                    setErrors(errorData);
                }

            } else {
                setErrors({ general: errorData });
            }
        }
    };

    if (defaultValues.loading) {
        return null;
    }

    return (
        <OrganisationForm
            handleSubmit={editOrganisation}
            errors={errors}
            defaultValues={defaultValues}
            title="edit_organisation"
        />
    );
}
