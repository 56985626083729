import { toastInfo } from "./toast";

export const formatPrice = (price) => {
    return (price / 100).toFixed(2) + "€";
};

export const formatPriceDifference = (priceDiff) => {
    return "(" + (priceDiff > 0 ? "+" : "-") + formatPrice(Math.abs(priceDiff)) + ")";
}

export const fixClock = (clock, tr) => {

    if(clock.length === 0) {
        return "";
    }
    
    if(clock.length <= 2) {
        clock = clock.padStart(2, "0") + ":00";
    } else {
        clock = clock.replace(".", ":").replace(",", ":");
    }

    if(clock.indexOf(":") === -1 && clock.length === 4) {
        clock = clock.slice(0, 2) + ":" + clock.slice(2);
    }

    let lastDigit = parseInt(clock[4]);
    if(lastDigit !== 0 && lastDigit !== 5) {
        if(lastDigit < 5) {
            clock = clock.slice(0, 4) + "0";
        } else {
            clock = clock.slice(0, 4) + "5";
        }
        toastInfo(tr("five_min_accuracy"));
    }

    return clock;
}

export const capitalize = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
};