import { useState, useEffect } from "react";

export function useLocalStorageState(name, initialValue) {

  if(!name || typeof name !== "string") {
    throw new Error("useLocalStorageState: name must be a string");
  }

  const [state, setState] = useState(initialValue);

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem(name, "state"));
    if(savedState) {
      setState(savedState);
    }
  }, []);

  useEffect(() => {
    if(state !== null && state !== initialValue) {
      localStorage.setItem(name, JSON.stringify(state));
    }
  }, [state]);
  
  return [state, setState];
}