import axios from "axios";
import useAuth from "../auth";

const baseUrl = "/api/business/counter";

const counterService = {
    openCounter: async (code) => {
        return (await axios.put(`${baseUrl}/${code}/open`, null, useAuth()))?.data;
    },
    closeCounter: async (code) => {
        return (await axios.put(`${baseUrl}/${code}/close`, null, useAuth()))?.data;
    },
    getCounter: async (code) => {
        return (await axios.get(`${baseUrl}/${code}`, useAuth()))?.data;
    }
};

export default counterService;