
import { useState, useEffect } from 'react';
import { Container, Input, IconText, Icon } from "../../ui-elements/basic-ui";
import ThemeButton from "../../ui-elements/theme-button";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import useTranslate from "../../util/dictionary";
import organisationService from "../../services/business/organisationService";
import tableService from '../../services/business/tableService';
import BackButton from '../../common/backbutton';
import TableForm from './tableForm';
import { localId } from '../../util/functions';
import { fixClock } from '../../util/format';
import { toastError } from '../../util/toast';

export default function CounterForm(props) {
    const { counters, setCounters, countersToDelete, setCountersToDelete, errors, addDefaultCounter } = props;
    const tr = useTranslate();
    const [copyProductModalOpen, setCopyProductModalOpen] = useState(false);
    const [allCounters, setAllCounters] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [productCounters, setProductCounters] = useState([]);

    useEffect(() => {
        const getCounters = async () => {
            const organisation = await organisationService.get();
            setAllCounters(organisation.counters);
            setAllProducts(organisation.products);
        };
        getCounters();
    }, []);

    useEffect(() => {
        const productCountersData = allCounters
            .map(counter => counter.counterName ? `${counter.barName} - ${counter.counterName}` : counter.barName)
        setProductCounters(productCountersData);
    }, [allCounters]);

    const handleCopyProduct = (event) => {
        const selectedCounter = allCounters.find(counter => counter.counterName ? `${counter.barName} - ${counter.counterName}` : counter.barName === productCounters);
        const selectedCounterProducts = allProducts.filter(product => product.counters.includes(selectedCounter.passcode));

        // TODO: Database call


    };

    const handleChange = (event) => {
        setProductCounters(event.target.value);
    };

    const getPasscode = async () => {
        return (await organisationService.generateUniqueCode()).passcode;
    };

    const addCounter = async () => {
        const nextCounters = [
            ...counters,
            {
                ...localId(),
                passcode: "...",
                barName: "",
                counterName: "",
                closingTimes: []
            }
        ];

        setCounters(nextCounters);

        getPasscode().then(passcode => {
            setCounters(nextCounters.map(c => {
                if (c._id === id) {
                    return { ...c, passcode };
                }
                return c;
            }));
        });
    };

    useEffect(() => {
        if (addDefaultCounter && counters.length === 0) {
            addCounter();
        }
    }, []);

    const handleTableEdit = async (description, code, tableId) => {
        //TODO: client\src\business\organisation\edit.js line 33

        const table = {
            description,
            code
        }

        try {

            await tableService.edit(tableId, { table });

            setCounters(counters.map(c => {
                if (c.tables.find(t => t._id === tableId)) {
                    return { ...c, tables: c.tables.map(t => t._id === tableId ? {...t, ...table} : t) };
                } else {
                    return c;
                }
            }));
        } catch (e) {
            toastError(tr("something_went_wrong"));
        }
    };

    return (
        <>
            <div style={{marginTop: "1em"}}>
                {
                    counters.map(counter =>
                        <Container
                            key={counter._id}
                            style={{marginBottom: "1em", padding: "0"}}
                        >
                            <div style={{backgroundColor: "black", padding: "1em", border: "5px dotted var(--dark-highlight)", }}>
                                <Input
                                    value={counter.barName}
                                    onChange={(e) => {
                                        setCounters(counters.map(c => {
                                            if (c._id === counter._id) {
                                                return { ...c, barName: e.target.value };
                                            }
                                            return c;
                                        }));
                                    }}

                                    required
                                    id="barName"
                                    label="bar_name"
                                    name="barName"
                                    error={errors["barName"]}
                                />
                                <Input
                                    value={counter.counterName}
                                    onChange={(e) => {
                                        setCounters(counters.map(c => {
                                            if (c._id === counter._id) {
                                                return { ...c, counterName: e.target.value };
                                            }
                                            return c;
                                        }));
                                    }}
                                    id="counters"
                                    label="counter_name"
                                    name="counters"
                                    error={errors["counterName"]}
                                />
                                <div style={{width: "80%", display: "inline-block"}}>
                                    <Input
                                        margin="normal"
                                        required
                                        name="passcode"
                                        label="passcode"
                                        id="passcode"
                                        onChange={(e) => {
                                            setCounters(counters.map(c => {
                                                if (c._id === counter._id) {
                                                    return { ...c, passcode: e.target.value.toLowerCase() };
                                                }
                                                return c;
                                            }));
                                        }}
                                        value={counter.passcode}
                                        error={errors["passcode"]}
                                        style={{
                                            textTransform: "uppercase"
                                        }}
                                    />
                                </div>
                                <div style={{width: "20%", display: "inline-block", transform: "translate(0%, 10%)"}}>
                                    <ThemeButton
                                        icon="refresh"
                                        onClick={async (e) => {
                                            const newPasscode = await getPasscode();
                                            setCounters(await counters.map(c => {
                                                if (c._id === counter._id) {
                                                    return { ...c, passcode: newPasscode };
                                                }
                                                return c;
                                            }));
                                        }}
                                    />
                                </div>
                                <ClosingTimeForm
                                    setCounters={setCounters}
                                    counters={counters}
                                    counter={counter}
                                    errors={errors["closingTimes.$"]}
                                />
                                <TableForm handleTableEdit={handleTableEdit} counter={counter} setCounters={setCounters} counters={counters} />

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setCopyProductModalOpen(true);
                                        }}
                                        style={{marginRight: "0.5em", marginTop: "0em"}}
                                    >
                                        {tr("copy_products_from")}
                                    </button>

                                    <button
                                        type="button"
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            setCounters(counters.filter(c => c._id !== counter._id))
                                            setCountersToDelete([...countersToDelete, counter._id]);
                                        }}
                                        style={{marginLeft: "0.5em", marginTop: "0em"}}
                                    >
                                        <IconText icon="delete" text={tr("delete")} black center/>
                                    </button>
                                </div>
                            </div>
                        </Container>
                    )
                }
                <button onClick={addCounter} >
                    <IconText
                        text={tr("add_counter")}
                        icon="plus"
                        black
                        center
                    />
                </button>
            </div>
            <Modal
                open={copyProductModalOpen}
                onClose={() => setCopyProductModalOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}

            >
                <Box bgcolor={"white"} padding={"1rem"}>
                    <Typography variant="h5" component="h2">
                        {tr("choose_products_to_copy")}
                    </Typography>
                    <br />
                    <FormControl>
                        <InputLabel>Counters</InputLabel>
                        <Select
                            value={
                                productCounters ? productCounters : ""
                            }
                            onChange={handleChange}
                            input={<OutlinedInput label={tr("counter")} />}
                        >

                            {allCounters.map((counter) => (
                                <MenuItem key={counter.passcode} value={
                                    counter.counterName ? `${counter.barName} - ${counter.counterName}` : counter.barName
                                }>
                                    <ListItemText primary={counter.counterName ? `${counter.barName} - ${counter.counterName}` : counter.barName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleCopyProduct()}
                        style={{ fontSize: 16 }}
                    >
                        {tr("copy_products")}
                    </Button>

                </Box>
            </Modal>
        </>
    );
}

function ClosingTimeForm ({counter, counters, setCounters, errors}) {
    const tr = useTranslate();
    const [infoOpen, setInfoOpen] = useState(false);

    const updateClosingTimes = (i, value) => {
        setCounters(counters.map(c => {
            if (c._id === counter._id) {
                const updatedClosingTimes = [...c.closingTimes];
                updatedClosingTimes[i] = value; 
                return { ...c, closingTimes: updatedClosingTimes};
            }
            return c;
        }))
    };

    return (
        <div>
            <h3
                style={{margin: 0}}
            >{tr("closing_times")}</h3>
            {
                infoOpen ? 
                    <p style={{fontSize: "0.5em"}}>
                        {tr("closing_times_desc")}
                    </p> :
                    <button
                        style={{
                            width: "7em"
                        }}
                        onClick={() => setInfoOpen(true)}
                    >
                        {tr("additional_info")}
                    </button>
            }
            <div style={{display: "flex"}}>
                {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day, i) =>
                    <Input
                        key={day}
                        label={day}
                        style={{flex: 1}}
                        divStyle={{marginRight: "0.3em"}}
                        onBlur={(e) => {
                            updateClosingTimes(i, fixClock(e.target.value, tr));
                        }}
                        error={errors}
                        value={counter.closingTimes?.[i] || ""}
                        onChange={(e) => updateClosingTimes(i, e.target.value)}
                    ></Input>
                )}
            </div>
        </div>
    );
}