import { useNavigate } from 'react-router-dom';
import { Mobile } from "../ui-elements/basic-ui";
import { useParams } from "react-router-dom";
import Header from "../ui-elements/header";
import useTranslate from "../util/dictionary";

const ErrorPage = ({ error, navigateTo, children, showGraphics = true }) => {
    const navigate = useNavigate();
    const tr = useTranslate();

    const params = useParams();

    if(params.error) {
        error = params.error;
    }

    function navigateToHome() {
        const extraPath = navigateTo ? navigateTo : '';
        navigate(`/${extraPath}`);
    }

    return (
        <Mobile footerGraphics pad>
            {showGraphics && <Header />}
            <p>{tr(error)}</p>
            {children}
            <button
                onClick={navigateToHome}
            >
                {tr("return")}
            </button>
        </Mobile>
    );
}

export default ErrorPage;
