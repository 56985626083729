import { useState } from "react";
import { Link } from "react-router-dom";
import useTranslate from "../util/dictionary";
import { Icon, IconText } from "../ui-elements/basic-ui";

const paths = [
  {title: "order_view", path: "/restaurant/", icon: "order"},
  {title: "product_view", path: "/restaurant/products", icon: "categories/wine2"},
  /*{title: "counter_view", path: "/restaurant/counters", icon: "bar-counter"},*/
  {title: "organisation_view", path: "/restaurant/organisation/edit", icon: "bar-counter"
  /*"office-building"*/},
  {title: "settings", path: "/restaurant/settings", icon: "settings"},
  {title: "logout", path: "/restaurant/logout", icon: "exit"}
]

export default function NavBar({style}) {
  const tr = useTranslate();

  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);

  return (
    <>
      <div id="navbar-mobile">
        <button
          onClick={() => setMobileNavbarOpen(!mobileNavbarOpen)}
          className={`mobile-navbar-button ${mobileNavbarOpen ? "open" : ""}`}
        >
          <Icon icon="navbar"/>
        </button>
        {
          mobileNavbarOpen &&
          <ul
            className="navbar-list navbar-list-mobile"
            onClick={() => setMobileNavbarOpen(false)}  
          >
            {paths.map(path => (
              <li className="navbar-item" key={path.title}>
                <Link to={path.path}>
                  <IconText icon={path.icon} text={tr(path.title)}/>
                </Link>
              </li>
            ))}
          </ul>
        }
      </div>
      <div id="navbar" style={style}>
        <img
          src="/images/logo.svg"
          style={{width: "60%", marginTop: "1em"}}
        />
        <ul className="navbar-list">
          {paths.map(path => (
            <li className="navbar-item" key={path.title}>
              <Link to={path.path}>
                <IconText icon={path.icon} text={tr(path.title)}/>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};