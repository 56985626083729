import axios from "axios";
const baseUrl = "/api/customer";

const customerService = {
    getOrderPage: async (id) => {
        return (await axios.get(baseUrl + "/orderPage/" + id))?.data;
    },
    getTables: async (id) => {
        return (await axios.get(baseUrl + "/tables/" + id))?.data;
    },
    createOrder: async (data) => {
        return (await axios.post(baseUrl + "/order", data))?.data;
    },
    finishOrder: async (data) => {
        return (await axios.post(baseUrl + "/order/callback", data))?.data;
    },
    checkOrder: async (restaurantId, id) => {
        return (await axios.get(`${baseUrl}/order/${restaurantId}/${id}`))?.data;
    },
    markReceived: async (id) => {
        return (await axios.put(`${baseUrl}/order/${id}/delivered`))?.data;
    },
    subscribeToNotifications: async (id, data) => {
        return (await axios.post(`${baseUrl}/order/${id}/createnotification`, data))?.data;
    },
    unsubscribeFromNotifications: async (id) => {
        return (await axios.post(`${baseUrl}/order/${id}/deletenotification`))?.data;
    }
};

export default customerService;