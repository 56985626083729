import {createContext, useContext, useState, useEffect} from "react";
import useTranslate from "../util/dictionary";

const LanguageContext = createContext(null);

function LanguageProvider(props) {
    const {children} = props;

    const [language, setLanguage] = useState("fi");

    useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if(savedLanguage) {
          setLanguage(savedLanguage);
      }
    }, []);

    const switchLanguage = () => {
      if(language === "fi") {
          setLanguage("en");
          localStorage.setItem("language", "en");
      } else {
          setLanguage("fi");
          localStorage.setItem("language", "fi");
      }
    };

    const value = {language};
 
    return (
      <LanguageContext.Provider value={value}>
        <button
          onClick={() => switchLanguage()}
          style={{
            width: "6em",
            margin: 0,
            position: "absolute",
            top: 0,
            padding: "0.7em",
            right: 0,
            backgroundColor: "inherit",
            color: "#fff",
            zIndex: 10000
          }}
        >
          {language === "fi" ? "English" : "suomi"}
        </button>
        {children}
      </LanguageContext.Provider>
    );
}

export const useLanguageContext = () => {
    const context = useContext(LanguageContext);
    if (context === undefined)
      throw new Error("LanguageContext must be used within it's provider");
    return context;
};
  
export default LanguageProvider;
