import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FullscreenSpinner } from "../ui-elements/spinner";

export default function Logout() {
  
  const navigate = useNavigate();
  
  useEffect(() => {
    localStorage.removeItem("token");
    setTimeout(() => {
      navigate("/login");
    }, 500);
  });
  return <FullscreenSpinner/>;
}