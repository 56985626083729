import { useState, useEffect } from "react";
import useTranslate from "../util/dictionary";
import ThemeButton from "../ui-elements/theme-button";
import { ReactSortable } from "react-sortablejs";
import { toastError } from "../util/toast";
import tagService from '../services/business/tagService';
import Modal from "../ui-elements/modal";
import { Icon } from "../ui-elements/basic-ui";
import { getTranslatedTag } from "../util/functions";
import { useLanguageContext } from "../context/language";
import DeleteButton from "../ui-elements/delete-button";

export default function TagModal({tagModalOpen, tags, setTags, setTagModalOpen}) {
  const tr = useTranslate();
  const { language } = useLanguageContext();

  const [editingTag, setEditingTag] = useState(null);
  const [loading, setLoading] = useState();

  const [name, setName] = useState("");
  const [name_fi, setName_fi] = useState("");
  const [icon, setIcon] = useState(null);
  const [showAllBtnVisible, setShowAllBtnVisible] = useState(false);

  useEffect(() => {
    if(editingTag === null) {
      setName("");
      setName_fi("");
      setIcon(null);
    } else {
      if(name === "" && name_fi === "") {
        setName(editingTag.name || "");
        setName_fi(editingTag.translations?.fi || "");
        setIcon(editingTag.icon || null);
      }
    }
  }, [editingTag]);

  const handleTagSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = {
        name: name,
        translations: {
          fi: name_fi
        },
        icon: icon,
        priority: tags.reduce((acc, tag) => tag.priority > acc ? tag.priority : acc, 0) + 1
      };

      if(editingTag._id) {
        const res = await tagService.update(editingTag._id, {tag: data});
        setTags([...tags.map(t => t._id === res._id ? res : t)]);
        setEditingTag(null);
        setLoading(false);
      } else {
        const res = await tagService.create({tag: data});
        setTags([...tags, res]);
        setEditingTag(null);
        setLoading(false);
      }
    } catch (e) {
      toastError(tr("something_went_wrong"));
    }
};
  const reorganizeTags = async (order) => {
    const oldOrder = [...tags];
    if(order.filter((o, i) => o._id.toString() !== tags[i]._id.toString()).length > 0) {
      setTags(order);
      try {
        const newOrder = await tagService.reorganize(order.map(o => o._id.toString()));
        setTags(newOrder);
      } catch (e) {
        setTags(oldOrder)
        toastError(tr("something_went_wrong"));
      }
    }
  };

  return (
    <Modal
        business
        open={tagModalOpen}
        onClose={() => setTagModalOpen(false)}
        style={{minWidth: "15em"}}
    >
      {
        editingTag !== null ?
          <form onSubmit={handleTagSubmit}>
            <h3>{editingTag._id ? tr("editing_tag") : tr("create_tag")}</h3>
            <label>{tr("english")}</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={() => setShowAllBtnVisible(true)}
            />
            
            <label>{tr("finnish")}</label>
            <input
              value={name_fi}
              onChange={(e) => setName_fi(e.target.value)}
              onBlur={() => setShowAllBtnVisible(true)}
            />
            
            <label>{tr("icon")}</label>
            <IconSelector
              str={name}
              str_fi={name_fi}
              selectIcon={setIcon}
              selectedIcon={icon}
              showAllBtnVisible={showAllBtnVisible}
              setShowAllBtnVisible={setShowAllBtnVisible}  
            />
            <ThemeButton
              type="submit"
              title={tr("confirm")}
              icon="accept"
              loading={loading}
            />
            <ThemeButton
              onClick={() => setEditingTag(null)}
              title={tr("cancel")}
              icon="cancel"
            />
          </form>
        :
        <div>
            <h3>{tr("all_tags")}</h3>
            <p style={{fontSize: "0.5em"}}>{tr("can_also_dragdrop_tags")}</p>
            <div>
              <ReactSortable
                list={tags}
                setList={reorganizeTags}
              >
                {tags.map(tag =>
                    <div key={tag._id} style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                      <div>
                        <h5>{getTranslatedTag(tag, language)}</h5>
                      </div>
                      <div>
                        <ThemeButton
                          onClick={() => setEditingTag(tag)}
                          icon="edit"
                        />
                        <DeleteButton
                          onDelete={async () => {
                            await tagService.delete(tag._id);
                            setTags(tags.filter(t => t._id !== tag._id));
                          }}
                        />
                      </div>
                    </div>
                )}
              </ReactSortable>
            </div>
            <button
                onClick={() => setEditingTag({})}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                {tr("create_tag")}
            </button>
        </div>
      }
    </Modal>
  );
}

const cocktail_search = ["coctail", "cocktail", "koktail", "mixed drinks", "sekoitetut juomat"];

const soda_search = ["soda", "fizzy", "lemonade", "limsa", "limonaati", "limu", "virvoitusjuoma"];

const beer_search = ["olut", "oluet", "kalja", "beer", "lager", "IPA", "APA", "Lambic", "Sour", "Bitter", "Pils", "Saison", "trappist"];

const icons = [
  {
    name: "Absinthe",
    icons: ["absinthe"],
    match: ["absint"]
  },
  {
    name: "Alcohol Free",
    icons: ["alcohol-free1", "alcohol-free2"],
    match: ["alkoholiton", "alkoholittomat", "alcohol free", "alcoholfree", "0%"]
  },
  {
    name: "Ale",
    icons: ["ale", "ale2"],
    match: ["ale", "tumma", "dark", "britti", "british", "porter", "portteri", "stout", "stautti", "IPA", "APA"]
  },
  {
    name: "Beer",
    icons: ["beer1", "beer2"],
    match: beer_search,
    matchIgnore: ["a can", "canned", "tölk", "pullo", "bottle"]
  },
  {
    name: "Bottled Beers",
    icons: ["beer_bottle1", "beer_bottle2", "beer_bottle3", "beer_bottle4", "beer_bottle5"],
    match: [...beer_search, "bottle", "pullo"],
    matchIgnore: ["a can", "canned", "tölk"]
  },
  {
    name: "Brandy",
    icons: ["brandy"],
    match: ["brandy", "cognaq", "konjak"]
  },
  {
    name: "Sparkling Wine",
    icons: ["champagne1", "champagne2", "champagne3"],
    match: ["kuohuviini", "sparkling wine", "champagne", "samppan", "shamppan"]
  },
  {
    name: "Can",
    icons: ["can1", "can2"],
    match: ["tölk", "can", "olut", "lonkero", "siideri", "beer", "long drink", "longdrink", "cider"],
    matchIgnore: ["hana", "tap", "bottle", "pullo"]
  },
  {
    name: "Cider",
    icons: ["cider"],
    match: ["siideri", "cider"]
  },
  {
    name: "Cocktail",
    icons: ["cocktail1", "cocktail2", "cocktail3", "cocktail4", "cocktail5", "cocktail6", "cocktail7", "cocktail8"],
    match: cocktail_search
  },
  {
    name: "Gin Tonic",
    icons: ["gin-tonic"],
    match: ["GT", "Gin", "Tonic", ...cocktail_search]
  },
  {
    name: "Warm Drinks",
    icons: ["irishcoffee"],
    match: ["coffee", "hot", "warm", "kuuma", "lämpimät"],
    matchIgnore: ["shot"]
  },
  {
    name: "Liquor",
    icons: ["liquor", "liquor2", "liquor3", "liquor4", "liquor5"],
    match: ["viina", "spirit", "liquor", "liquour", "likööri", "väkevä", "shot", "kirkkaat", "kirkas", "gin"]
  },
  {
    name: "Lonkero",
    icons: ["lonkero2", "lonkero3"],
    match: ["lonkero", "long drink", "longdrink", "cider", "siideri"]
  },
  {
    name: "Martini",
    icons: ["martini"],
    match: ["martini", "marttiini", ...cocktail_search]
  },
  {
    name: "Rum",
    icons: ["rum"],
    match: ["rommi", "rum"]
  },
  {
    name: "Sangria",
    icons: ["sangria1", "sangria2"],
    match: ["sangria", "kannu", "pitcher", "jug"]
  },
  {
    name: "Sherry",
    icons: ["sherry"],
    match: ["sherry", "sherri"]
  },
  {
    name: "Coke",
    icons: ["soda1"],
    match: soda_search
  },
  {
    name: "Soda",
    icons: ["soda2", "soda3"],
    match: ["seltzer", "seltseri", "lonkero", "long drink", "longdrink", ...soda_search]
  },
  {
    name: "Tap",
    icons: ["tap1", "tap2"],
    match: ["hana", "tap"]
  },
  {
    name: "Tequila",
    icons: ["tequila"],
    match: ["tequila", "tekila"],
  },
  {
    name: "Vermouth",
    icons: ["vermouth"],
    match: ["vermut", "vermouth"]
  },
  {
    name: "Vodka",
    icons: ["vodka"],
    match: ["vodka", "viina"]
  },
  {
    name: "Wheat Beer",
    icons: ["wheatbeer"],
    match: ["vehnä", "wheat", "beer", "olut", "oluet", "weizen", "oktoberfest"],
    matchIgnore: ["bottle", "pullo", "a can", "canned", "tölk"]
  },
  {
    name: "Whisky",
    icons: ["whisky1", "whisky2"],
    match: ["whisk", "viski"]
  },
  {
    name: "Wine",
    icons: ["wine1", "wine2", "wine3", "wine4", "wine5", "wine6"],
    match: ["viini", "wine", "rose", "rosé", "sherry", "madeira", "portviini", "port wine"],
    matchIgnore: ["kuohu", "sparkling"]
  }
];

const iconButtonStyle = {
  backgroundColor: "var(--medium)",
  margin: ".25em",
  padding: ".25em",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
};

const IconSelector = ({
  str, str_fi,
  showAllBtnVisible, setShowAllBtnVisible, selectIcon, selectedIcon
}) => {

  const [visibleIcons, setVisibleIcons] = useState([]);
  const tr = useTranslate();

  const addSelectedIconToVisible = () => {
    
  }

  useEffect(() => {
    if(selectedIcon && selectedIcon !== "") {
      const selectedIconVisible = visibleIcons.find(icon => {
        return icon.icons.includes(selectedIcon);
      });

      if(!selectedIconVisible) {
        setVisibleIcons([...visibleIcons, icons.find(icon => icon.icons.includes(selectedIcon))]);
      }
    }
  }, [visibleIcons]);

  const updateVisibleIcons = () => {
    const matches = icons.map(icon => {
      let matches = 0;

      if(icon.icons.includes(selectedIcon)) {
        matches = 99;
      }

      icon.match.forEach(match => {
        if(str.toUpperCase().includes(match.toUpperCase()) || str_fi.toUpperCase().includes(match.toUpperCase())) {
          if(icon.matchIgnore) {
            const matchIgnores = icon.matchIgnore.find(mi =>
              str.toUpperCase().includes(mi.toUpperCase()) ||
              str_fi.toUpperCase().includes(mi.toUpperCase())
            );

            if(matchIgnores) {
              //do nothing
            } else {
              matches++;
            }
          } else {
            matches++;
          }
        }
      });

      return { icon, matches };
    })
    .filter(obj => obj.matches > 0)
    .sort((a,b) => a.matches - b.matches)
    .map(obj => obj.icon);

    setVisibleIcons(matches);
  };
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateVisibleIcons();
    }, 500);

    return () => clearTimeout(timeout);
  }, [str, str_fi]);

  return (
    <div style={{
      display: "flex",
      flexWrap: "wrap"
    }}>
      {
        visibleIcons.map(icon => {
          return icon.icons.map(iconStr => (
            <div
              key={iconStr}
              style={{
                border: `2px solid var(${selectedIcon ===  iconStr ? "--highlight" : "--medium"})`,
                ...iconButtonStyle
              }}
              onClick={() => {
                selectIcon(iconStr)
              }}
            >
              <Icon icon={`categories/${iconStr}`}/> 
            </div>
          ));
        })
      }
      {
        visibleIcons.length !== icons.length && showAllBtnVisible ?
        <div
          onClick={() => setVisibleIcons(icons)}
          style={{...iconButtonStyle, maxWidth: "1.5em"}}
        >
          <p style={{fontSize: "0.5em", textWrap: "wrap"}}>{tr("show_all")}</p>
        </div> : null
      }
    </div>
  );
}