import Order from "./order";

export default function OrderContainer ({orders, action}) {
    return (
        <div className="order-container">
            {
                orders.map(order =>
                    <Order order={order} action={() => action(order._id)} key={order._id} />
                )
            }
        </div>
    )
}