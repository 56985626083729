import axios from "axios";
import useAuth from "../auth";

const baseUrl = "/api/business/product";

const productService = {
    get: async () => {
        return (await axios.get(baseUrl, useAuth()))?.data;
    },

    create: async (data) => {
        return (await axios.post(baseUrl, data, useAuth()))?.data;
    },

    update: async (id, data) => {
        return (await axios.put(baseUrl + "/" + id, data, useAuth()))?.data;
    },

    delete: async (id) => {
        return (await axios.delete(baseUrl + "/" + id, useAuth()))?.data;
    },

    setVisibility: async (id, visibility) => {
        return (await axios.put(baseUrl + "/" + id + "/visibility", { visibility }, useAuth()))?.data;
    },

    reorganizeProducts: async (data) => {
        return (await axios.put(baseUrl + "/reorganize", data, useAuth()))?.data;
    }
};

export default productService;