import "./modal.css";
import { Icon } from "./basic-ui";

export default function Modal({open, style, children, onClose, business}) {
  
  if(!open) {
    return null;
  }

  return (
    <>
      <div
        className="modal-background"
        onClick={onClose}
      ></div>
      <div className={`modal ${business ? "modal-business" : ""}`} style={style}>  
        <button
          style={{
            width: "4em",
            height: "4em",
            backgroundColor: "transparent",
            position: "absolute",
            top: "0em",
            right: "0em",
            marginTop: 0,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <Icon icon="close"/>
        </button>
        {children}
      </div>
    </>
  );
}