import "./spinner.css";

export function Spinner({style}) {
  return (
    <div className="spinner" style={style}/>
  );
}

export function FullscreenSpinner() {
  return (
    <div className="spinner-parent">
      <div className="spinner spinner-full"/>
    </div>
  );
}