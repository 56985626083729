import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useTranslate from "../util/dictionary";
import businessUserService from "../services/business/userService";
import Graphics from '../ui-elements/graphics';
import { Button } from '../ui-elements/basic-ui';
import { useLoading } from "../context/loading";
import { setToken } from '../util/jwt';
import TagModal from "./tagModal";

export default function SignIn() {
  const navigate = useNavigate();
  const tr = useTranslate();
  const {loading, setLoading} = useLoading();

  const [error, setError] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setLoading("login", true);
      const res = await businessUserService.login({
        username: data.get('email'),
        password: data.get('password')
      });

      setToken(res.sessionToken);
      navigate("/restaurant");
      setLoading("login", false);

    } catch (e) {
      setLoading("login", false);
      setError(e.response.data);
    }
  };

  return (
    <div style={{maxWidth: "18em", margin: "auto"}}>
        <img src="/images/logo.svg" style={{width: "5em"}} />
        <Graphics/>
        <h3>{tr("login")}</h3>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">{tr("username_or_email")}</label>
            <input
                required
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <label htmlFor="email">{tr("password")}</label>
            <input
                required
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <Button
              type="submit"
              loading={loading["login"]}
            >
                {tr("sign_in")}
            </Button>
            { 
              error ?
                <p style={{color: "white", fontSize: "0.8em"}}>{tr(error)}</p> :
                null
            }
        </form>
        <div
            style={{
                display: "flex",
                fontSize: "0.7em",
                marginTop: "2em"
            }}
        >
            <div style={{flex: 1}}>
                <Link href="#">
                    {tr("forgot_password")}
                </Link>
            </div>
            <div style={{flex: 1}}>
                <Link onClick={() => navigate("/signup")} href="#">
                    {tr("create_account")}
                </Link>
            </div>
        </div>
    </div>
  );
}