import { Container } from '../ui-elements/basic-ui';
import { useState } from "react";
import useTranslate from '../util/dictionary';

export default function Order({order, action, children}) {
    const tr = useTranslate();
    const [fading, setFading] = useState(false);

    return (
        <>        
            <Container
                className={fading ? "order-fade-out" : "order-fade-in"}
                style={{
                    width: "100%",
                    minWidth: "100%",
                    minHeight: "3em",
                    padding: 0,
                    textAlign: "left",
                    position: "relative",
                    userSelect: "none",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "16px"
                }}
                onClick={() => {
                    setFading(true);
                    setTimeout(() => action(), 5);
                }}
            >
                <div
                    style={{
                        backgroundColor: "white",
                        height: "100%"
                    }}
                >
                    {order.items[0].product.image &&
                        <img
                            src={order.items[0].product.image}
                            style={{
                                height: "80px",
                                objectPosition: "center",
                            }}
                        />
                    }
                </div>
                <div style={{ flex: 3, paddingLeft: "8px" }}>
                    <h4
                        style={{
                            margin: "0.5em 0.5em 0 0.5em",

                            fontSize: "0.8em"
                        }}
                    >
                        {order.items.map((item, i) => 
                            <span style={{display: "block"}} key={i}>{item.product.name + (item.amount > 1 ? " x" + item.amount : "")}
                            <span style={{fontSize: "0.7em", margin: 0, display: "block", color: "var(--highlight)"}}>{(item.variants && item.variants.length > 0 ? item.variants.map(vrnt => vrnt.map(v => v.name).join(" - ")).join(", ") : "")}</span></span>
                        )}
                    </h4>
                    <h6
                        className="highlight"
                        style={{ margin: "0 0.5em 0.5em 0.5em", fontSize: "18px"}}
                    >
                        {order.deliveryId.toUpperCase()}</h6>
                    {children}
                </div>
                {order && order.table && order.table.code !== null ? (
                    <h5 style={{marginRight: "1em"}}>
                        {tr("table")} {order.table.code}
                    </h5>
                    ) : null
                }
            </Container>
            
        </>
    );
}