import { Input } from "../ui-elements/basic-ui";
import useTranslate from "../util/dictionary";
import { useEffect, useState } from "react";
import userService from "../services/business/userService";
import { useNavigate } from "react-router-dom";
import { toastSuccess } from "../util/toast";

export default function Settings() {
    const tr = useTranslate();
    
    const [user, setUser] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState();
    const [firstPassword, setFirstPassword] = useState("");
    const [secondPassword, setSecondPassword] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        async function getUser() {
            const user = await userService.get();
            setUser(user);
        }
        getUser();
        setIsLoaded(true);
    }, []);

    const handleSubmit = (event) => {
        setSaving(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const oldPassword = data.get("oldPassword");

        if(firstPassword) {
            if(firstPassword !== secondPassword) {
                setErrors({password_again: "passwords_dont_match"});
                setSaving(false);
                return;
            }
        }

        userService.update({
            username: data.get("username"),
            email: data.get("email"),
            oldPassword,
            newPassword: firstPassword
        }).then(() => {
            setSaving(false);
            toastSuccess(tr("settings_saved"));
            navigate("/restaurant");
        }).catch((e) => {
            const errorData = e.response.data;
            setSaving(false);
            if (typeof errorData === "object") {
                setErrors(errorData);
            }
        }
        );
    };

    useEffect(() => {
        setErrors({second_new_password: null})
    }, [secondPassword])

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{maxWidth: "20em", margin: "auto"}}>
                <h3>
                    {tr("settings")}
                </h3>

                {user && user.name && user.email && (
                    <form onSubmit={
                        (event) => {
                            handleSubmit(event);
                        }
                    }>
                        <Input
                            required
                            id="username"
                            name="username"
                            label="username"
                            autoComplete="username"
                            defaultValue={user.username}
                        />
                        <Input
                            required
                            id="email"
                            name="email"
                            label="email"
                            autoComplete="email"
                            defaultValue={user.email}
                        />
                        <hr />
                        <h3>{tr("change_password")}</h3>
                        <Input
                            id="oldPassword"
                            name="oldPassword"
                            label="old_password"
                            type="password"
                            error={errors?.oldPassword}
                            autoComplete="off"
                        />                        
                        <Input
                            id="first_new_password"
                            name="first_new_password"
                            label="first_new_password"
                            type="password"
                            value={firstPassword}
                            error={errors?.password}
                            autoComplete="off"
                            onChange={(event) => {
                                setFirstPassword(event.target.value);
                            }}
                        />
                        <Input
                            id="second_new_password"
                            error={errors?.password_again}
                            label="second_new_password"
                            name="second_new_password"
                            type="password"
                            value={secondPassword}
                            autoComplete="off"
                            onChange={(event) => {
                                setSecondPassword(event.target.value);
                            }}
                        />
                        <button type="submit" disabled={saving} >
                            {tr("save")}
                        </button>
                    </form>
                )
            }
        </div>
    );
}