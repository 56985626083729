import { useState, useEffect, useRef } from "react";
import { Icon } from "./basic-ui";
import "./select.css";

export const CheckboxSelect = ({value, onChange, single, children, style, optionsStyle, keepOpen}) => {

  const options = useRef(null);
  const input = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const outsideClick = (e) => {
      //if clicked outside of options
      if(options.current && !options.current.contains(e.target) && !input.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", outsideClick);

    return () => {
      document.removeEventListener("click", outsideClick);
    }
  }, []);

  return (
    <div style={{
      width: "100%",
      position: "relative", 
      backgroundColor: "var(--medium)",
      ...style
    }}
    >
      <div
        style={{
          height: "100%"
        }}
        className="select"
        ref={input}
        onClick={() => setOpen(!open)}
      >
        <div
          className="select-values" 
        >
          {value || "-"}
        </div>
        <Icon icon={open ? "arrow-down" : "arrow-right"} s={12}/>
      </div>
      { open && 
        <div className="select-options" onClick={() => !keepOpen ? setOpen(false): null} style={optionsStyle} ref={options}>
          {children}
        </div>
      }
    </div>
  );
}

export const SelectItem = ({children, onSelect, style}) => {
  return (
    <div className="select-item" onClick={onSelect} style={style}>
      {children}
    </div>
  )
}