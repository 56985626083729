export default function FooterGraphics() {
  return (
    <img
      src="/images/footer-cropped.svg"
      style={{
        position: "fixed",
        bottom: 0,
        left: "50%",
        opacity: 0.3,
        width: "100%",
        transform: "translateX(-50%)",
        maxWidth: "20em",
        zIndex: -3
      }}
    />
  );
};