import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { FullscreenSpinner } from "../ui-elements/spinner";

const LoadingContext = createContext(null);

function LoadingProvider(props) {
    const { children } = props;

    const [fullscreenLoading, setFullscreenLoading] = useState({});
    const [loading, setLoading] = useState({});

    const value = {
      setFullscreenLoading: (key, val) => {
        setFullscreenLoading({...loading, [key]: val});
      },
      loading,
      setLoading: (key, val) => {
        setLoading({...loading, [key]: val})
      }
    };

    if(Object.values(fullscreenLoading).includes(true)) {
      return (
        <FullscreenSpinner />
      );
    }

    return (
        <LoadingContext.Provider value={value}>
            {children}
        </LoadingContext.Provider>
    );
}

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (context === undefined)
        throw new Error("LoadingContext must be used within it's provider");
    return context;
};

export default LoadingProvider;
