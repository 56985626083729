import NavBar from "./navBar";
import Graphics from "../ui-elements/graphics";
import { useLocation } from "react-router-dom";

export default function BusinessView({children}) {
  const location = useLocation();

  //No navbar for the order view
  if(location.pathname.includes("/restaurant/orders/")) {
    return (
      <div>
        <Graphics/>
        {children}
      </div>
    )
  } else {
    return (
      <div style={{height: "100vh"}}>
        <NavBar/>
        <div id="navbar-margin">
          <Graphics className="navbar-graphics"/>
          <div style={{padding: "1em"}}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}