import { useState } from 'react';
import FooterGraphics from "./footer-graphics";
import useTranslate from "../util/dictionary";
import { Spinner } from "./spinner";

export const Mobile = ({children, style, footerGraphics, pad}) => (
  <>
    <div
      id="mobile-container"
      style={{
        maxWidth: "18em",
        margin: "auto",
        position: "relative",
        height: "100%",
        padding: pad ? "1em" : 0,
        paddingTop: "1em",
        ...style
      }}
    >
      {children}
    </div>
    {footerGraphics && <FooterGraphics/>}
  </>

)

export const Container = ({children, style, onClick, className = ""}) => (
  <div
    className={"container " + className}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

export const Footer = ({children}) => (
  <div className="footer"><div>{children}</div></div>
)

export const Icon = ({icon, s = "1.5em", black, yellow, style}) => {

  let filter = null;

  if(black) {
    filter = "invert(0)";
  }

  if(yellow) {
    filter = "invert(89%) sepia(100%) brightness(85%) saturate(1000%) hue-rotate(-20deg)";
  }

  return (
    <>
      <img className="icon" src={`/icons/${icon}.svg`}
        style={{
          width: s,
          height: s,
          filter,
          ...style
        }}
      />
    </>
  );
}

export const IconText = ({icon, text, black, yellow, s = 16, center}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      ...(center ? {justifyContent: "center"} : {})
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "10px"
      }}
    >
      <Icon
        icon={icon}
        s={s * 1.8}
        style={{
          marginTop: -s,
          marginBottom: -s
        }}
        black={black}
        yellow={yellow}
      />
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <p style={{
        fontSize: s,
        display: "inline-block",
        marginLeft: "8px"
      }}>{text}</p>
    </div>
  </div>
);

export const Input = (props) => {
  const tr = useTranslate();
  //pass in input props and "error", maybe "label", maybe "helperText"
  const [helperPopup, setHelperPopup] = useState(false);

  const passableProps = {...props};
  delete passableProps.divStyle;
  delete passableProps.error;
  delete passableProps.label;
  delete passableProps.helperText;
  delete passableProps.textarea;

  return (
    <div
      title={props.helperText}
      style={props.divStyle}
    >
      <label>{tr(props.label)} {
        props.helperText ?
          <div
            style={{
              cursor: "pointer",
              display: "inline"
            }}
            onClick={() => setHelperPopup(!helperPopup)}
          >
            {"?"}
          </div> : null}
      </label>
      {helperPopup && <label style={{display: "block"}}>{props.helperText}</label>}
      { props.textarea ?
        <textarea
          {
            ...passableProps
          }
          
          style={{width: "calc(100% - 1em)"}}
        /> :
        <input
          {
            ...{
              ...passableProps,
              style: {
                ...(props.error ? {border: "2px solid #FF6666"} : {}),
              ...passableProps.style
              }
            }
          }
        />
      }
      <label style={{color: "#FF6666", display: "block"}}>{props.error ? tr(props.error) : ""}</label>
    </div>
  );
}

export const Button = (props) => {

  const data = {...props};

  data.loading = undefined;
  data.children = undefined;

  if(props.loading) {
    return (
      <button {...data} disabled className="button-loading">
        <Spinner style={{borderColor: "white", borderWidth: "3px", height: "13px", width: "13px", margin: "auto"}} />
      </button>
    );
  } else {
    return (
      <button {...data}>{props.children}</button>
    );
  }
}