import { createTheme } from '@mui/material/styles';

export const orange = '#FFA62B';

export const theme = createTheme({
    typography: {
        fontFamily: "Rubik, sans-serif",
        button: {
            textTransform: 'none',
            fontSize: "20px"
        }
    },
    palette: {
        primary: {
            main: orange,
            dark: '#e39427'
        },
        secondary: {
            main: '#489FB5',
        },
        dark: {
            main: '#4A5855'
        }
    },
});