import useTranslate from '../util/dictionary';
import {useState} from 'react';
import ThemeButton from './theme-button';
import Modal from './modal';

export default function DeleteButton({onDelete, errors, style, title}) {
  const tr = useTranslate();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  return (
      <>
      <div style={{display: "inline-block"}}>
          <ThemeButton
              style={style}
              onClick={(e) => {
                  e.stopPropagation();
                  setDeleteConfirm(true)
              }}
              title={title}
              icon="delete"
          />
          <Modal
              open={deleteConfirm}
              onClose={() => {
                  setDeleteConfirm(false);
              }}
              style={{width: "10em", textAlign: "center"}}
          >
              <span>{tr("delete")}?</span>
              <br/>
              <ThemeButton
                  onClick={onDelete}
                  style={{
                      color: "#FFF",
                      margin: 8,
                  }}
                  icon="accept"
                  title={tr("confirm")}
              />
              <ThemeButton
                  onClick={(e) => {
                      e.stopPropagation();
                      setDeleteConfirm(false)
                  }}
                  title={tr("cancel")}
                  icon="cancel"
              />
          </Modal>
      </div>
      {
          errors?.["delete"] ?
              <Alert severity="error">{tr(errors["delete"])}</Alert> :
              null
      }
      </>
  );
}