import { useState, useEffect } from "react";
import "./confirm-slider.css";

const ConfirmSlider = ({onSlide, text}) => {
  const [sliderValue, setSliderValue] = useState(0);
  const [isFirefox, setIsFirefox] = useState(false);

  //there is a problem as you can just click straight to 100 without pulling. We measure mid points so you need to drag
  const [primed, setPrimed] = useState(false);

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSliderValue(value);

    if (value === 100 && primed) {
      onSlide();
    } else if (value > 0) {
      setPrimed(true);
    }
  };

  useEffect(() => {
    // Check if the browser is Firefox
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isFirefoxBrowser = userAgent.includes('firefox');

    setIsFirefox(isFirefoxBrowser);
  }, []);

  const reset = () => {
    setSliderValue(0);
    setPrimed(false);
  }

  return (
    <>
      <div
        onMouseUp={reset}
        onTouchEnd={reset}
        className="slider-container"
      >
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={handleSliderChange}
          className="slider"
        />
        <div
          className="slider-tail"
          style={{
            display: isFirefox ? "none" : "block",
            transform: `translateY(-2em) translateX(${sliderValue * .9 - 95}%)`}}
        />
        <div className="slider-text">
          {text}
        </div>
      </div>
      <div style={{height: ".5em"}}/>
    </>
  );
};

export default ConfirmSlider;